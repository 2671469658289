import * as React from 'react';
import { Button, TextField } from "@mui/material";
import { useState} from "react";
import { Box } from "@mui/system";

export default function ContactForm() {

    const[fName,setFName]=useState("");
    const[lName,setLName]=useState("");
    const[email,setEmail]=useState("");
    const[message,setMessage]=useState("");

    const inputRef = React.useRef(null);

    function handleSubmit(e) {
        e.preventDefault();

        console.log(fName)
        console.log(message)
        
        let formData = new FormData()
        formData.append("form-name", "contact")
        formData.append("First Name", `${fName}`)
        formData.append("Last Name", `${lName}`)
        formData.append("Email", `${email}`)
        formData.append("Message", `${message}`)

        setFName("")
        setLName("")
        setEmail("")
        setMessage("")

        fetch("/", {
        method: "POST",
        headers: {
        "Content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
        })
        .then(() => alert("Form Submitted Successfully!"))
        .catch((error) => alert("Seems like there was an error! you can email me at uanjumm@gmail.com"));

    }

    return (
        <form
        ref={inputRef}
        name="contact"
        data-netlify = "true"
        method="POST"
        style = {{marginTop: "1rem", minWidth: "300px"}}>
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field"/>
            <Box display = "flex" flexDirection = "column">
                <TextField
                    style={{backgroundColor: '#e5e6e5', marginBottom: "1rem"}}
                    required id="outlined-required"
                    name="First Name"
                    label="First Name"
                    value = {fName}
                    onChangeCapture={(e)=>setFName(e.target.value)}
                    onChange={(e)=>setFName(e.target.value)}
                />
                <TextField
                    style = {{backgroundColor: '#e5e6e5', marginBottom: "1rem"}}
                    required id="outlined-required"
                    name="Last Name"
                    label="Last Name"
                    value = {lName}
                    onChangeCapture={(e)=>setLName(e.target.value)}
                    onChange={(e)=>setLName(e.target.value)}
                />
                <TextField
                    style = {{backgroundColor: '#e5e6e5', marginBottom: "1rem"}}
                    required id="outlined-required"
                    name="Email"
                    label="Email Address"
                    value = {email}
                    onChangeCapture={(e)=>setEmail(e.target.value)}
                    onChange={(e)=>setEmail(e.target.value)}
                />
                <TextField
                    required id="outlined-required"
                    style = {{backgroundColor: '#e5e6e5', marginBottom: "1rem"}}
                    name="Message"
                    label="Message"
                    value = {message}
                    onChangeCapture={(e)=>setMessage(e.target.value)}
                    onChange={(e)=>setMessage(e.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                />
                <Button variant="contained" type = "submit" onClick = {handleSubmit} sx = {{marginBottom:4}}>SUBMIT</Button>
            </Box>
        </form>
    );
}