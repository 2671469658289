import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {useNavigate} from "react-router-dom";


export default function Pcard1() {

  let navigate = useNavigate();


    return(

    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea onClick = {() => navigate("/projects/obd_cluster")} href="#proj_1_begin">
        <CardMedia
          component="img"
          height="140"
          image={require("../res/obd_cluster.png")}
          alt="obd_main_page"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            OBD Cluster
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Python GUI created to read sensor data from a ELM327 device connected to a car's OBDII port
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
)}