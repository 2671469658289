import React from "react";
import { Box } from "@mui/system";
import { createTheme, responsiveFontSizes, ThemeProvider, Toolbar, Typography } from "@mui/material";


import background from "../res/white_patt_back.jpg";
import Pcard1 from "../components/Pcard1";
import Pcard2 from "../components/Pcard2";
import Pcard3 from "../components/Pcard3";



let theme = createTheme();
theme = responsiveFontSizes(theme)

function Projects() {

    const backgroundImageStyle = {
        backgroundImage:`url(${background})`,
        //backgroundPosition: "center",
        backgroundSize: "cover",
        height:'100%',
        minHeight: '100vh'
        //sx = {{border: 1, borderColor: 'error.main', margin:1}} to make a border

    }

  return (
    <div  style = {backgroundImageStyle}>
        <Toolbar/>
        <ThemeProvider theme = {theme}>
            <Box  sx = {{margin:1}}>
            <Typography variant = 'h4' sx = {{fontSize: '2rem'}}>Check out the projects below!</Typography>
            <Box display={"flex"} flexDirection = "row" justifyContent={"center"} flexWrap = "wrap">
              <div style={{padding: "10px"}}>
                <Pcard1/>
              </div>
              <div style={{padding: "10px"}}>
                <Pcard2/>
              </div>
              <div style={{padding: "10px"}}>
                <Pcard3/>
              </div>
            </Box>
            </Box>    
        </ThemeProvider>
    </div>
  )
}

export default Projects;