import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {useNavigate} from "react-router-dom";


export default function Pcard3() {

  let navigate = useNavigate();


    return(

    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea onClick = {() => navigate("/projects/movie")} href="#proj_3_begin">
        <CardMedia
          component="img"
          height="140"
          image={require("../res/registereduser_movies.JPG")}
          alt="obd_main_page"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Movie Booking Web App
          </Typography>
          <Typography variant="body2" color="text.secondary">
            A Full Stack application with back end created in Java Spring Boot and front end in React
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
)}