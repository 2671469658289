import React from "react";
import { Box } from "@mui/system";
import {Toolbar, Typography } from "@mui/material";
import background from "../res/white_patt_back.jpg";


function PROJ3_MOVIE() {

    let windowSize = window.innerWidth;
    console.log(windowSize)

    let textStyles = {
        h2: {fontWeight: "Medium"}

    }

    if (windowSize < 500) {
        console.log("Small Screen")

        textStyles = {
            h1: {fontSize: "3rem"},
            h2: {fontSize: "2rem" ,fontWeight: "medium"},
            h4: {fontSize: "1rem"},
            h5: {fontSize: "1rem"}
        }
    }

    const backgroundImageStyle = {
        backgroundImage:`url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        //height:'100vh',
        //backgroundRepeat: "repeat"
        //sx = {{border: 1, borderColor: 'error.main', margin:1}} to make a border
    }

    return (
        <div  style = {backgroundImageStyle} >
            <Toolbar id="proj_3_begin"/>
            <Box marginLeft = "15%" marginRight= "15%" marginTop="10px">
                <Typography variant="h2" sx = {textStyles.h2}> Full Stack Movie Booking Web Application</Typography>
                <br/>
                <img src = {require("../res/registereduser_movies.JPG")} alt = "movie posters" width = "100%"/>

                <Typography variant="h5" sx = {textStyles.h4}>
                <br/>
                A Full Stack Application! Front end was created in React. The back end was created in Java Spring Boot.
                <br/>
                Spring boot is a java based framework that is used to easily create production grade spring based applications.  We used it to create a REST API through which our web application can talk to our database (developed in mySQL). The database was populated with a couple of random movies, showtimes, seats and users. 
                <br/><br/>

                The application has the following features:
                <br/><br/>
                1.	User can login as guest, select a movie, theatre, showtime, seat and checkout. After checking out the user will receive a confirmation email and a receipt to their email address
                <br/>
                2.	A registered user can do the same tasks as a guest user, except they have access to pre-released movies, and can check out faster since all their payment information is already saved in the database.
                <br/><br/>
                All movies in the database have a release date and a pre-release date. This is so that registered users can access movies that have been pre-released but not yet released. The guest users will only be able to see if today’s date is greater than the release date.
                <br/><br/>

                <Box display = "flex" flexDirection={"row"} justifyContent = "center" flexWrap={"wrap"}>
                    <Box  display={"flex"} flexDirection = "column" alignItems={"center"} marginRight = "4px" maxWidth="520px">
                        <img src = {require("../res/registereduser_movies.JPG")} alt = "movie posters" width = "100%"/>
                        <Typography align="center">Figure 1: Movies for registered users</Typography>
                    </Box>
                    <br></br>
                    <Box  display={"flex"} flexDirection = "column" alignItems={"center"} marginLeft = "4px" maxWidth="480px">
                        <img src = {require("../res/movieselection.JPG")} alt = "movie posters" width = "85%"/>
                        <Typography align="center">Figure 2: Movies for guest users</Typography>
                    </Box>
                </Box>

                <br/>

                The registered user has to register and pay a subscription fee to be able to have to access to the early movies. Their subscription is controlled by the Accounts tab, where they can access their subscription status and if expired, choose to the renew subscription for 1-year at a time. Since their payment info is already saved, they also have the option to update their payment info in this tab.
                <br/><br/>
                <Box  display={"flex"} flexDirection = "column" alignItems={"center"} marginRight = "4px" maxWidth="100%">
                        <img src = {require("../res/registered_user_account.JPG")} alt = "movie posters" width = "100%"/>
                        <Typography align="center">Figure 3: Account management page for registered users </Typography>
                </Box>
                <br/>
                The database tables are connected to each other through foreign keys. The ticket is connected to the seat through seat_id foreign key, seat is connected to show time, showtime is connected to theatre, and finally theatre is connected to movie. The theatre selection is simply different formats of video showings e.g. 3D/Regular/AVX/IMAX
                <br/><br/>

                <Box  display={"flex"} flexDirection = "column" alignItems={"center"} marginRight = "4px" maxWidth="100%">
                        <img src = {require("../res/seatselection.JPG")} alt = "movie posters" width = "100%"/>
                        <Typography align="center">Figure 4: Seat selection page</Typography>
                </Box>
                <br/>

                The seats are essentially buttons with the “disabled” property set to true for the ones that have already been booked (“is_booked” flag from the database). Once a seat is booked, a POST method is triggered to add the ticket details to the ticket table and update the seat “is_booked” attribute.
                
                <br/><br/>

                Code can be found on my github! Shoot me a message if you think of ways I can improve on this!

                <br/><br/>

                </Typography>

            </Box>
        </div>
      )
    }
    
    export default PROJ3_MOVIE;