import {Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import './About.css';

import ContactForm from "../components/Contactform";
import background from "../res/white_patt_back.jpg";
import Appbar from "../components/Appbar";
import Pcard1 from "../components/Pcard1";
import Pcard2 from "../components/Pcard2";
import Pcard3 from "../components/Pcard3";


function About() {

    let windowSize = window.innerWidth;
    console.log(windowSize)

    let textStyles = {
        h4: {fontWeight: "Medium"},
        h3: {fontWeight: "Regular"}
    }

    if (windowSize < 500) {
        console.log("Small Screen")

        textStyles = {
            h1: {fontSize: "3rem"},
            h2: {fontSize: "2rem"},
            h3: {fontSize: "1.5rem", fontWeight: "Regular"},
            h4: {fontSize: "1rem", paddingTop: 2, fontWeight: "Medium"},
            h5: {fontSize: "1rem"},
        }
    }

    const backgroundImageStyle = {
        backgroundImage:`url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height:'100%',
        minHeight: '100vh'
        
    }

  return (
    <div style = {backgroundImageStyle} width = "100%">
        <Appbar/>
        <Toolbar id = "about"/>
        <Box style = {{display: 'flex', margin: "10px", flexDirection:'column'}} sx = {{fontFamily: '@fontsource/puppies-play'}} >
            <Box sx = {{display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', flexWrap: "wrap"}} >
                <img src={require('../res/profile_pic-modified.png')} alt='logo' style={{maxWidth:"20%"}}/>
                <Box display={"flex"} flexDirection= "column" alignContent={"center"} margin = "10px">
                    <Typography variant = 'h1' sx = {textStyles.h1} align = "center"> Uzair Anjum</Typography>
                    <Typography variant = 'h2' sx = {textStyles.h2}> (Software Engineer) </Typography>
                </Box>
            </Box>

            <Box className = "centerBox" >
            <Typography variant = 'h4' sx = {textStyles.h4} style = {{padding: '10px'}} align = "center"> 
                    Whether developing new applications or optimizing existing ones, I thrive on the opportunity to work on meaningful projects and make a positive impact.
            </Typography>    
            </Box>

            <Typography variant = 'h4' sx = {textStyles.h4} fontWeight = "Medium" marginTop={2}>Tech Stack</Typography>

            <ul>
                <li>Web: Javascript, ReactJS, HTML, CSS, Angular, NodeJS</li>
                <li>Languages: dotNET, C#, Python, Java, C++ </li>
                <li>Machine Learning: TensorFlow, Pytorch, SciKitLearn </li>
            </ul>   
            
            <Typography variant = 'h4' sx = {textStyles.h4} marginTop={1}>Work Experience</Typography>

            <p>National Oilwell Varco (NOV) - Software Engineer - Present</p>
            <ul> 
                <li>Working as a Full-Stack Engineer </li>
                <li>dotNet, C#, Angular</li>
            </ul>

            <p>Motorola Solutions - Project HomeBase (Full Stack) - 2023</p>

            <ul> 
                <li>Helping create digital solution to help individuls experiencing precarious housing or living unsheltered</li>
                <li>Developed FE in React and BE in Node </li>
            </ul> 

            <p>University of Calgary - Movie Theatre Booking Application (Full Stack) - 2022</p>

            <ul>
                <li>Created front end in React and created a REST api in Java Spring Boot</li>
                <li>Created relational database in mySQL </li>
            </ul>

            <p>National Oilwell Varco (NOV) - Reliability Engineer - 2020</p>

            <ul>
                <li>Performed failure investigation for a $6M tools and component market</li>
                <li>Responsible for providing detailed failure analysis reports and presentations to customers outlining root cause analysis</li>
            </ul>

            <Typography className="rightBox" variant = 'h4' sx = {textStyles.h4} marginTop={1}>Education</Typography>

            <ul>
                <li>M.Eng. in Software Engineering (University of Calgary 2023, 3.9/4.0 GPA)</li>
                <li>B.Sc. in Petroleum Engineering (University of Alberta 2018 3.7/4.0 GPA)</li>
            </ul>

            <Typography variant = 'h4' sx = {textStyles.h4} marginTop={1}>Hobbies</Typography>

            <ul>
                <li>3D Printing/Prototyping</li>
                <li>Automation (Trading Bot Project)</li>
                <li>Cars (Digital OBD Project)</li>

            </ul>

            <Box sx = {{margin:1}}>
            <Typography variant = "h3" sx = {textStyles.h3} align = "center">Check out some of my projects!</Typography>
            <br></br>
            <Box display={"flex"} flexDirection = "row" justifyContent={"center"} flexWrap = "wrap">
              <div style={{padding: "10px"}}>
                <Pcard1/>
              </div>
              <div style={{padding: "10px"}}>
                <Pcard2/>
              </div>
              <div style={{padding: "10px"}}>
                <Pcard3/>
              </div>
            </Box>
            </Box>    

            <Box display = "flex" alignItems={"center"} flexDirection = "column" margin={"1rem"}>
                <Typography variant="h5" sx = {textStyles.h5} marginBottom = "1rem">
                    Get in touch!
                </Typography>
                <ContactForm/>
            </Box>
        </Box>
    </div>
  );
}

export default About;