import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useNavigate } from "react-router-dom";
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import React from "react";


export default function Appbar() {

    let navigate = useNavigate();


    return (
        <Box display = 'flex'>
        <AppBar color = "inherit">
            <Toolbar display = 'flex' position = 'sticky'>
            <img href="#about" src={require('../res/logo-no-background.png')} alt='logo' style={{maxWidth:40}} onClick={() => navigate("/")} />
            <LinkedInIcon style={{marginLeft: "10px"}} size = 'large' onClick={() =>  window.open('https://www.linkedin.com/in/uzairanjum/', '_blank')}/>
            <GitHubIcon style={{marginLeft: "10px"}} size = 'large' onClick={() =>  window.open('https://github.com/uanjum', '_blank')}/>
            <Typography variant="h6" sx = {{flexGrow:1, m:1}} ></Typography>
            <IconButton></IconButton>
            <Button color = 'inherit' href="#projects" onClick={() => navigate("/")}>Projects</Button>
            <Button color = 'inherit' href="#about" onClick={() => navigate("/")}>About</Button>
            </Toolbar>
        </AppBar>
        </Box> 
    );
};

