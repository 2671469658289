import React from "react";
import { Box } from "@mui/system";
import {Toolbar, Typography } from "@mui/material";
import background from "../res/white_patt_back.jpg";


function PROJ2_TRADER() {

    let windowSize = window.innerWidth;
    console.log(windowSize)

    let textStyles = {
        h2: {fontWeight: "Medium"}

    }

    if (windowSize < 500) {
        console.log("Small Screen")

        textStyles = {
            h1: {fontSize: "3rem"},
            h2: {fontSize: "2rem" ,fontWeight: "medium"},
            h4: {fontSize: "1rem"},
            h5: {fontSize: "1rem"}
        }
    }

    const backgroundImageStyle = {
        backgroundImage:`url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        //height:'100vh',
        //backgroundRepeat: "repeat"
        //sx = {{border: 1, borderColor: 'error.main', margin:1}} to make a border
    }

    return (
        <div  style = {backgroundImageStyle} >
            <Toolbar id="proj_2_begin"/>
            <Box marginLeft = "15%" marginRight= "15%" marginTop="10px">
                <Typography variant = "h2" sx = {textStyles.h2}> Stock Trading Bot based on Twitter Sentiment</Typography>
                <br/>
                <img src = {require("../res/trading_back.jpg")} alt = "min_max_values" width = "100%"/>

                <Typography variant = "h5" sx = {textStyles.h4}>
                <br/><br/>

                This project is more related to data science and machine learning with one goal. Let’s automate the stock picking, let the bot trade it for us and get rich (easy right?). After numerous failed quantitative strategies, e.g. SMA, EMA crossovers, RSI, support resistance mapping, I pivoted to sentiment analysis on social media. Keep in mind this is a very naïve strategy.
                <br/><br/>

                I decided to use Twitter data as my social media platform, and two different sets of the NLP libraries in python.
                <br/><br/>
                1.	NLTK Vader sentiment
                <br/>
                2.	Flair NLP library
                <br/><br/>
                Using twint to fetch tweets from the Twitter feed. The algorithm would go as follows:
                <br/><br/>
                1.	Get the top 250 TSX listed stocks (Some really high priced stocks were filtered out since I was not going to trade with that much capital). This step was only computed once in the beginning
                <br/>
                2.	TSX opens at 930AM. At 10AM, get all tweets for each stock from 12AM (start of day) to present (10AM). This would include premarket chatter and the first half hour of market open.
                <br/>
                3.	For each stock, run all the tweets gathered through the sentiment analyzer of choice (vader or flair) and calculate the sentiment.
                <br/>
                4.	Rank the stocks based on who has the most count of positive tweets
                <br/>
                5.	Buy the top three ranked stocks on the list (equal weightage across all three)
                <br/>
                6.	Sell all positions 10 minutes before market close.
                <br/><br/>
                To get the price data for the stocks, yfinance library was used. It seemed easy to use and quite reliable since it pulls data directly from Yahoo Finance.
                <br/><br/>
                This strategy was naïve since, there was minimal preprocessing done on the tweets. Ideally stop words should have been removed and word stemming should have been performed. Also there were no commission fees that were considered, since there are various platforms that charge various commissions, and there are also ones that don’t charge any.
                <br/><br/>
                Another factor that was not considered was slippage, although for our case since we are not trying to sell at certain price point, the script just sells whatever we have at market close, it would still be worthwhile to explore in the future. 
                <br/><br/>
                I completed and deployed the bots in August and gave each one a starting capital of $10,000 and let this bot do its thing since August 13, 2022 untill December 16, 2022. The results were as follows:
                <br/><br/>
                <Box display = "flex" flexDirection={"column"} alignContent = "center">
                    <img src = {require("../res/trading_results.JPG")} alt = "results_graph" width = "100%"/>
                    <br/>
                    <img src = {require("../res/trading_results_2.png")} alt = "results_table" width = "40%" style = {{marginRight: "auto", marginLeft: "auto"}}/>
                    <br/>
                </Box>

                The S&P buy and hold strategy turned out to be the best over time. Although the sentiment strategy using vader sentiment library was not far off. It should be noted that both sentiment strategies bought stocks irrelative of the market capitalization. Again, this is a very basic way of looking at the data. 
                <br/><br/>
                The difference in the results between the two sentiment libraries was quite a bit. This reinforced the idea that not all sentiment libraries are trained/created equally. One library might understand the financial jargon better then others. 
                <br/><br/>
                For the best result, I would have to create my own training, testing data using a large amount of twitter data which would be very time consuming but worth it since it would capture the modern financial jargon used these days e.g., “X stock going to the moon!”
                <br/><br/>
                If you would like to take a look at the code or to let me know how to improve it, please contact me in the About page!
                <br/><br/>
                <br/><br/>

                </Typography>

            </Box>
        </div>
      )
    }
    
    export default PROJ2_TRADER;