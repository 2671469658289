import './App.css';
import Appbar from './components/Appbar';
import {Routes, Route} from 'react-router-dom';
import About from './pages/About'
import Projects from './pages/Projects';
import PROJ1_OBD from './pages/Proj1_obd';
import PROJ2_TRADER from './pages/Proj2_trader';
import PROJ3_MOVIE from './pages/Proj3_movie';
import { ThemeProvider, createTheme } from "@mui/material";


function App() {
  let theme = createTheme({
    typography: {
        fontFamily: 'Rubik',
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
  })

  return (
    <>
      <ThemeProvider theme = {theme}>
      <Appbar/>
      <Routes>
        <Route path = "/" element = {<About/>}/>
        <Route path = "/projects" element = {<Projects/>}/>
        <Route path = "/projects/obd_cluster" element = {<PROJ1_OBD/>}/>
        <Route path = "/projects/trader" element = {<PROJ2_TRADER/>}/>
        <Route path = "/projects/movie" element = {<PROJ3_MOVIE/>}/>
      </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
